import DataS from "./data.s";

export default class EmployeesS extends DataS {
  getEmployee(id) {
    return this.get('/students/' + id)
  }

  uploadCV({FILE, STUDENT_ID}) {
    return this.post('/students/cv', {FILE, STUDENT_ID})
  }

  closeSign(STUDENT_ID) {
    return this.post('/students/close-sign', {STUDENT_ID})
  }

  contactUs(
    OBJECT,
    MESSAGE,
    FULLNAME,
    EMAIL,
    STUDENT_ID,
    NUMPHONE) {
    return this.post('/students/contact-us', {
      OBJECT,
      MESSAGE,
      FULLNAME,
      EMAIL,
      STUDENT_ID,
      NUMPHONE
    })
  }

  setGmailAccount(STUDENT_ID, GMAIL_ACCOUNT) {
    return this.post('/students/gmail-account', {GMAIL_ACCOUNT, STUDENT_ID})
  }

  updateFamilial(
    {
      CIVILSTATUS,
      SPFIRSTNAME,
      SPLASTNAME,
      SPBIRTHDATE,
      EMERGNUM1,
      FATHER_NAME,
      FATHER_JOB,
      FATHER_BIRTH,
      FATHER_CONTACT,
      MOTHER_NAME,
      MOTHER_JOB,
      MOTHER_BIRTH,
      MOTHER_CONTACT,
      EMERGNAME1,
      STUDENT_ID
    }) {
    return this.patch('/students/update/familial', {
      CIVILSTATUS,
      SPFIRSTNAME,
      SPLASTNAME,
      SPBIRTHDATE,
      FATHER_NAME,
      FATHER_JOB,
      FATHER_BIRTH,
      FATHER_CONTACT,
      MOTHER_NAME,
      MOTHER_JOB,
      MOTHER_BIRTH,
      MOTHER_CONTACT,
      EMERGNUM1,
      EMERGNAME1,
      STUDENT_ID
    })
  }

  updatePersonnal({LASTNAME, FIRSTNAME, DENOMINATION, BIRTHPLACE, BIRTHDATE, GENDER, COUNTRY, STUDENT_ID}) {
    return this.patch('/students/update/personal', {
      LASTNAME,
      FIRSTNAME,
      DENOMINATION,
      BIRTHPLACE,
      BIRTHDATE,
      GENDER,
      COUNTRY,
      STUDENT_ID
    })
  }

  updateAddress({NUMPHONE, NUMPHONE2, TOWN, DISTRICT, PRECINCT, STUDENT_ID, RELIGION, ORIGIN_REGION}) {
    return this.patch('/students/update/address', {
      NUMPHONE,
      NUMPHONE2,
      TOWN,
      DISTRICT,
      PRECINCT,
      ORIGIN_REGION,
      RELIGION,
      STUDENT_ID,
    })
  }

  updateFinancial({
                    NIU, CNPSYN = false, CNPSNUM, BASESALARY, HOURRATE,
                    MINISTERIAL_DECREE,
                    CNPS_LINK,
                    NIU_LINK,
                    PRESENCE_ATTEST, STUDENT_ID
                  }) {
    return this.patch('/students/update/financial', {
      NIU, CNPSYN, CNPSNUM, MINISTERIAL_DECREE, CNPS_LINK, NIU_LINK,
      PRESENCE_ATTEST, BASESALARY, HOURRATE, STUDENT_ID
    })
  }

  updateIdentification({
                         IDENTIFICATION,
                         IDENTIFNUM,
                         IDENTIFPLACE,
                         IDENTIFSTART,
                         IDENTIFEND,
                         STUDENT_ID,
                         IDENTITY1,
                         IDENTITY2
                       }) {
    return this.patch('/students/update/identification', {
      IDENTIFICATION,
      IDENTIFNUM,
      IDENTIFPLACE,
      IDENTIFSTART,
      IDENTIFEND,
      IDENTITY1,
      IDENTITY2,
      STUDENT_ID
    })
  }

  updateTravelIdentification(
    {
      IDENTIFICATION_PASSPORT,
      IDENTIFNUM_PASSPORT,
      IDENTIFPLACE_PASSPORT,
      IDENTIFSTART_PASSPORT,
      IDENTIFEND_PASSPORT,
      STUDENT_ID_PASSPORT,
      IDENTITY1_PASSPORT,
      IDENTITY2_PASSPORT,
      STUDENT_ID,
    }) {
    return this.patch('/students/update/identification_passport', {
      IDENTIFICATION_PASSPORT,
      IDENTIFNUM_PASSPORT,
      IDENTIFPLACE_PASSPORT,
      IDENTIFSTART_PASSPORT,
      IDENTIFEND_PASSPORT,
      STUDENT_ID_PASSPORT,
      IDENTITY1_PASSPORT,
      IDENTITY2_PASSPORT,
      STUDENT_ID
    })
  }

  updateProfessional({
                       LASTEMPLOYER,
                       LASTJOB,
                       LASTJOBEND,
                       LASTJOBSTART,
                       LASTJOBSTILL,
                       ACTIVITY_PRINCIPAL,
                       STUDENT_ID,
                       SPECIALITY,
                       TITLE,
                       GRADES
                     }) {
    return this.patch('/students/update/professional', {
      LASTEMPLOYER,
      LASTJOB,
      LASTJOBEND,
      LASTJOBSTART,
      LASTJOBSTILL,
      ACTIVITY_PRINCIPAL,
      STUDENT_ID,
      GRADES,
      TITLE,
      SPECIALITY
    })
  }

  updatePaymentMode({
                      PAYMODE, CASHIER_CODE,
                      ACCOUNT_BIRTHDATE,
                      ACCOUNT_NUM,
                      RIB_LINK,
                      ACCOUNT_LASTNAME,
                      ACCOUNT_FIRSTNAME, RIB_KEY, STUDENT_ID
                    }) {
    return this.patch('/students/update/pay-mode', {
      PAYMODE, CASHIER_CODE,
      ACCOUNT_BIRTHDATE,
      ACCOUNT_NUM,
      RIB_LINK,
      ACCOUNT_LASTNAME,
      ACCOUNT_FIRSTNAME,
      RIB_KEY, STUDENT_ID
    })
  }

  updateLastDiploma({files, STUDENT_ID}) {
    return this.patch('/students/update/training', {
      files: files.map(e => {
        let obj = {...e, file: e.base64}
        delete obj.base64
        return obj
      }), STUDENT_ID
    })
  }

  updateStatus({STATUS, STUDENT_ID}) {
    return this.patch('/students/update/status', {STATUS, STUDENT_ID})
  }

  defineLastDiploma(STUDENT_ID, id) {
    return this.patch('/students/update/diploma', {id, STUDENT_ID})
  }

  removeFile(file, col, STUDENT_ID) {
    return this.patch('/students/files/remove', {file, col, STUDENT_ID})
  }
}
