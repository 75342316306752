import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {FormattedMessage} from "react-intl";
import Alert from '@mui/material/Alert';

const MySnackbar = (props) => {
    return (
        <Snackbar open={props.open} autoHideDuration={6000} onClose={() => props.setClose()}>
            <Alert onClose={() => props.setClose()} severity={props.severiry} variant='filled'>
                <FormattedMessage id={props.message}/> !
            </Alert>
        </Snackbar>
    )
}

export default MySnackbar;
