import DataS from "./data.s";

export default class AuthS extends DataS {
  async login(email, matricule) {
    try {
      console.log('Attempting login with:', { email, matricule });
      const response = await this.post('/auth/student/sign-in', { email, matricule });
      console.log('Login response:', response);
      return response;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async register(matricule, birthdate) {
    try {
      console.log('Attempting login with:', { birthdate, matricule });
      const response = await this.post('/auth/student/sign-up', {matricule, birthdate})
      console.log('Login response:', response);
      return response;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } 
  }
  
  verifyEmail(email, matricule) {
    console.log(email,matricule)
    return this.post('/auth/student/email-verification', {email, matricule})
  }
  
  confirmEmail(token) {
    return this.post('/auth/student/email-confirmation', {token})
  }
  
  codeVerification(SIGN_CODE, STUDENT_ID) {
    return this.post('/auth/student/code-verification', {SIGN_CODE, STUDENT_ID})
  }
}
