import CookieH from "../helpers/cookie.h";
import DataS from "./data.s";

export default class StudentS extends DataS {
    loginStudents(form) {
      return this.post('/auth/students/', form )
    }
    listSubjects( STUDENT_ID) {
      return this.get('/students/subjects/'+ STUDENT_ID)
    }
  }