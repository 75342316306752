import axios from 'axios'
import CookieH from "../helpers/cookie.h";
import CookieC from "../constants/cookie.c";

export default class DataS {

  constructor() {
    this.client = axios.create({
      baseURL: CookieC.SERVER,
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true 
    })

    this.client.interceptors.response.use(res => {
      console.log('Request being sent:', res);
      return res;
    }, error => {
      console.error('Request error:', error);
      return Promise.reject(error.response);
    });

    this.client.interceptors.response.use(
      response => {
        console.log('Response received:', response);
        return response;
      },
      error => {
        console.error('Response error:', error);
        return Promise.reject(error);
      }
    );
  }

  post = (url, data, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser()?.TOKEN}`
      
    return this.client.post(url, data, {
      headers: params
    })
  }

  patch = (url, data, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser()?.TOKEN}`

    return this.client.patch(url, data, {
      headers: params
    })
  }

  get = (url, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser()?.TOKEN}`

    return this.client.get(url, {
      headers: params
    })
  }
}
